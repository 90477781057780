import React, { useState, useEffect } from "react"
import Axios from "axios"
import PropTypes from "prop-types"
import withLocation from "./withLocation"
import Listing from "./listing"
import { navigate, Link } from "gatsby"
const SiteSearchComponent = ({ search }) => {
  var { query } = search
  const [Query, setQuery] = useState(query)

  console.log("Search Query", Query)
  const [Lists, SetLists] = useState([])
  const [Loader, SetLoader] = useState("load")
  function Slug(
    spaceDisplayName,
    gofloatersSpaceName,
    monthPassAvailable,
    dayPassAvailable,
    locality,
    city,
    spaceSubType
  ) {
    var Locality = ""
    if (locality !== "") {
      Locality = locality
      Locality = Locality.trim().replace(".", "")
    } else {
      Locality = ""
    }
    var str,
      slug = ""
    if (gofloatersSpaceName) {
      var str = gofloatersSpaceName
      str = str + "-" + spaceDisplayName + "-" + Locality + "-" + city
      str = str.trim()
      slug = str.replace(/\s+/g, "-").toLowerCase()
      slug = slug.replace("&", "and")
      slug = slug.replace("'", "")
      slug = slug.replace(",", "")
      slug = slug.replace("é", "e")
      slug = slug.replace(".", "-")
      slug = slug.replace("|", "-")
      slug = slug.replace("---", "-")
      slug = slug.replace("--", "-")
      slug = slug.replace("--", "-")
    }
    if (gofloatersSpaceName === "N/A") {
      var str = spaceDisplayName.replace(/\s+/g, "-").toLowerCase()
      str = str + "-" + locality.trim().replace(".", "") + "-" + city
      str = str.trim()
      slug = str.replace(/\s+/g, "-").toLowerCase()
      slug = slug.replace("&", "and")
      slug = slug.replace("'", "")
      slug = slug.replace(",", "")
      slug = slug.replace("é", "e")
      slug = slug.replace(".", "-")
      slug = slug.replace("|", "-")
      slug = slug.replace("---", "-")
      slug = slug.replace("--", "-")
    }
    

    if ((spaceSubType ?? "").includes("Office Space") && dayPassAvailable===true) {
      return (slug = "/coworking-space/" + slug)
    } else if ((spaceSubType ?? "").includes("Office Space") && monthPassAvailable===true) {
      return (slug = "/office-space/" + slug)
    } else if ((spaceSubType ?? "").includes("Meeting Space")) {
      return (slug = "/meeting-space/" + slug)
    }
    return slug
  }
  function spaceImages(photos) {
    var spaceImages = "https://placeholder.com/600"
    if (photos) {
      spaceImages = photos[0].replace(
        "https://firebasestorage.googleapis.com/v0/b/gofloaters.appspot.com/o",
        "https://cdn.app.gofloaters.com"
      )
    }
    return spaceImages
  }

  function spaceLocality(locality) {
    var spaceLocation = ""
    if (locality) {
      spaceLocation = locality.locality
    }
    return spaceLocation
  }
  function spaceCity(locality) {
    var spaceLocation = ""
    if (locality) {
      spaceLocation = locality.city
    }
    return spaceLocation
  }
   function spaceSubType(SubType) {
    var spaceSubType = ""
    if (SubType) {
      spaceSubType = SubType.toString()
    }
    return spaceSubType
  }

  const fetchDetails = async () => {
    const response = await Axios.get(
      `https://gofloaters.web.app/spaces/allactive`,
      {}
    )

    let responsevalue = Object.values(response.data)
    const details = responsevalue
    const allList = details.map(detail => ({
      spaceId: detail.spaceId,
      spaceImage: spaceImages(detail.photos),
      spaceTitle: detail.spaceDisplayName,
      spaceGFName: detail.gofloatersSpaceName,
      spaceType: detail.spaceType,
      spaceDesc: detail.spaceDesc,
      spaceAddress: spaceLocality(detail.address),
      officeSpaceType: detail.officeSpaceType,
      spaceDisplayName: detail.spaceDisplayName,
      spaceSubType: spaceSubType(detail.spaceSubType) ,
      monthPassAvailable: detail.monthPassAvailable,
      dayPassAvailable: detail.dayPassAvailable,
      hourPassAvailable: detail.hourPassAvailable,
      OriginalName: detail.originalName,
      subType: detail.spaceSubType,
      slug: Slug(
        detail.spaceDisplayName,
        detail.gofloatersSpaceName,
        detail.monthPassAvailable,
        detail.dayPassAvailable,
        spaceLocality(detail.address),
        spaceCity(detail.address),
       detail.spaceSubType
      ),
    }))  

    SetLists(allList)
    SetLoader("completed")
  }

  useEffect(() => {
    fetchDetails()
  }, [])
 
 const NewList = Lists.filter(list => ((list.slug ?? "").toLowerCase().search(query) >= 0 || (list.slug ?? "").toLowerCase().search(query) >= 0 || (list.OriginalName ?? "").toLowerCase().search(Query.toLowerCase()) >= 0 && list.spaceGFName !== "N/A") && ((list.subType ?? "").includes("Office Space") || (list.subType ?? "").includes("Meeting Space")))

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="listingpageTitle">Search Results</h1>
            {NewList.map((List, i) => {
                return (
                  <div>
                    <Link
                      to={List.slug}
                      style={{
                        color: "#404040",
                        padding: "10px 0px 10px 0px",
                        display: "block",
                      }}
                    >
                      <h3 style={{ fontWeight: "bold" }}>
                        {List.spaceGFName}{" "}
                        {List.OriginalName ? " - " + List.OriginalName : ""}{" "}
                      </h3>
                      <p className="text">{List.spaceDesc}</p>
                    </Link>
                  </div>
                )
              
            })}            {NewList
              ? ""
              : "<div className='text-center'><h2>Serach Not Found</h2></div>"}
          </div>
        </div>
      </div>
    </div>
  )
}

SiteSearchComponent.propTypes = {
  search: PropTypes.object,
}

export default withLocation(SiteSearchComponent)
